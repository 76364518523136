<template>
  <VueTuiEditor
    ref="vueTuiEditor"
    class="MarkdownEditor"
    v-bind="{
      ...props,
      height,
      options: {
        usageStatistics: false,
        ...options,
      },
    }"
    v-on="{ ...$listeners, change: onChange }"
  />
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css'
import 'highlight.js/styles/github.css'
import '../scss/tui-editor.sass'
import 'prismjs/themes/prism.css'
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css'

import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight'
import Prism from 'prismjs'

const VueTuiEditor = () => import(
  /* webpackChunkName: "tui-editor" */
  /* webpackPrefetch: true */
  '@toast-ui/vue-editor'
).then(module => module.Editor)

export default {
  name: 'MarkdownEditor',

  components: {
    VueTuiEditor,
  },

  inheritAttrs: false,

  model: {
    prop: 'source',
    event: 'input',
  },

  props: {
    source: { type: String, default: '' },
    wysiwyg: { type: Boolean, default: true },
    height: { type: String, default: null }, // 'auto', '100%', '500px'
    placeholder: { type: String, default: '' },
    options: { type: Object, default: () => ({}) },
  },

  computed: {
    mode() { return this.wysiwyg ? 'wysiwyg' : 'markdown' },

    props() {
      return {
        ...this.$attrs,
        initialValue: this.source,
        initialEditType: this.mode,
        options: {
          linkAttribute: { target: '_blank' },
          ...(this.$attrs.options || {}),
          placeholder: this.placeholder,
          plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
        },
      }
    },
  },

  watch: {
    async source() {
      // https://nhn.github.io/tui.editor/latest/ToastUIEditor#setMarkdown
      await VueTuiEditor()
      await this.$nextTick()
      const editor = this.getEditor()
      if (this.source !== editor.getMarkdown()) {
        editor.setMarkdown(this.source, false)
      }
    },
    async mode() {
      // https://nhn.github.io/tui.editor/latest/ToastUIEditor#setMode
      await VueTuiEditor()
      await this.$nextTick()
      const editor = this.getEditor()
      editor.changeMode(this.mode)
    },
  },

  methods: {
    onChange(...args) {
      // https://nhn.github.io/tui.editor/latest/ToastUIEditor#getMarkdown
      this.$emit('input', this.$refs.vueTuiEditor.invoke('getMarkdown'))
      if (this.$listeners.change) this.$listeners.change(...args)
    },

    getEditor() {
      return this.$refs.vueTuiEditor.editor
    },

    // for tests
    async _depsResolved() {
      await VueTuiEditor()
    },
  },
}
</script>
