var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VueTuiEditor',_vm._g(_vm._b({ref:"vueTuiEditor",staticClass:"MarkdownEditor"},'VueTuiEditor',{
    ..._vm.props,
    height: _vm.height,
    options: {
      usageStatistics: false,
      ..._vm.options,
    },
  },false),{ ..._vm.$listeners, change: _vm.onChange }))
}
var staticRenderFns = []

export { render, staticRenderFns }